import React from "react";
import { Text, List } from "@mantine/core";

export const MembershipBenefitsList = ({
  membershipPriceAmount,
  formattedInterval,
}) => (
  <List mt="sm" mb="sm">
    {[
      { text: "Background checks", strong: true },
      { text: "College student verification", strong: true },
      {
        text: "Occupational accident insurance that protects you on every Wyndy job.",
        strong: "Occupational accident insurance",
      },
      {
        text: "Fraud protection to guarantee you are paid for every Wyndy job.",
        strong: "Fraud protection",
      },
      {
        text: `Renews at $${membershipPriceAmount}/${formattedInterval} Cancel anytime`,
        strong: `Renews at $${membershipPriceAmount}/${formattedInterval}`,
      },
    ].map(({ text, strong }, index) => (
      <List.Item key={index}>
        <Text component="span" fw={700}>
          {typeof strong === "string" ? strong : text}
        </Text>
        {typeof strong === "string" ? text.replace(strong, "") : ""}
      </List.Item>
    ))}
  </List>
);
