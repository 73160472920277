import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Alert, Button, Group, Paper, Space } from "@mantine/core";

export const SetupIntentForm = ({
  clientSecret,
  onSubmit,
  onChange,
  onReady,
  ...props
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [setupIntentStatus, setSetupIntentStatus] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null || stripe == null) {
      return;
    }

    setLoading(true);
    setErrorMessage("");
    setSetupIntentStatus(null);

    try {
      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError?.message) {
        setErrorMessage(submitError.message);
        setLoading(false);
        return;
      }

      // Confirm the setup intent
      const result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${window.location.href}`,
        },
        redirect: "if_required",
      });

      if (result.error) {
        setErrorMessage(result.error.message);
      } else {
        // Handle non-redirecting scenarios
        const setupIntent = result.setupIntent;
        setSetupIntentStatus(setupIntent.status);

        if (setupIntent.status === "succeeded") {
          if (typeof onSubmit === "function") {
            onSubmit(event);
          }
        }
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Paper radius="xs" px={20} py={20} withBorder>
        <PaymentElement
          {...props}
          onReady={onReady}
          onChange={(event) => {
            setErrorMessage("");

            if (typeof onChange === "function") {
              onChange(event);
            }
          }}
        />

        {errorMessage.length > 0 && (
          <Alert
            variant="filled"
            color="red"
            mt="xs"
            title="Add Payment Method Error"
          >
            {errorMessage}
          </Alert>
        )}

        {setupIntentStatus && setupIntentStatus !== "succeeded" && (
          <Alert
            variant="filled"
            color="yellow"
            mt="xs"
            title="Setup Intent Status"
          >
            Setup Intent Status: {setupIntentStatus}
          </Alert>
        )}

        <Space h="xs" />
        <Group position="center" mt="xs">
          <Button fullWidth onClick={handleSubmit} loading={loading}>
            Continue
          </Button>
        </Group>
      </Paper>
    </>
  );
};
