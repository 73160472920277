import React from "react";
import { Text } from "@mantine/core";

export const PricingInfo = ({
  isFreeTrial,
  hasActivePromo,
  formattedAmount,
  formattedInterval,
  monthlyPrice,
  membershipInterval,
}) => {
  if (isFreeTrial && hasActivePromo) {
    return (
      <Text mt="sm">
        <Text
          component="span"
          color="strong-cta.0"
          size="xl"
          fw={1000}
          style={{ fontSize: "42px" }}
        >
          First month, FREE! 🎉
        </Text>
      </Text>
    );
  }

  if (hasActivePromo) {
    return (
      <Text mt="sm">
        <Text
          component="span"
          color="strong-cta.0"
          size="xl"
          fw={1000}
          style={{ fontSize: "42px" }}
        >
          First month, only ${formattedAmount}! 🎉
        </Text>
      </Text>
    );
  }

  return (
    <>
      <Text size="xl" weight="500" fw="bold">
        Trust & Safety Fee
      </Text>
      <Text mt="sm">
        <Text
          component="span"
          color="strong-cta.0"
          size="xl"
          fw={700}
          style={{ fontSize: "31px" }}
        >
          ${formattedAmount}
        </Text>{" "}
        <Text component="span" color="strong-cta.0" fw={500}>
          / {formattedInterval}
        </Text>
        {membershipInterval === "year" && (
          <Text component="span" fw={500}>
            {" "}
            (only ${monthlyPrice} a month)
          </Text>
        )}
      </Text>
    </>
  );
};
