/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import findIndex from "lodash/findIndex";
import { yupResolver } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import useGetUserData from "@/hooks/useGetUserData";
import useSaveFormData from "@/pages/Application/hooks/useSaveFormData";
import useSubmitForm from "@/pages/Application/hooks/useSubmitForm";
import { useApplicationForm } from "@/pages/Application/form-context";
import applicationState from "@/pages/Application/atoms/application.atom";
import currentStepState from "@/pages/Application/selectors/currentStep.selector";
import flow from "@/pages/Application/flow";
import formPreValidation from "@/utils/formPreValidation";
import useAuth from "@/hooks/useAuth";

const useForm = () => {
  const navigate = useNavigate();
  const { data, refetch, isLoading } = useGetUserData();
  const { saveData, isLoadingSave } = useSaveFormData();
  const { auth } = useAuth();
  const { submitForm, isLoadingSubmit } = useSubmitForm();
  const [application, setApplication] = useRecoilState(applicationState);
  const currentStep = useRecoilValue(currentStepState);
  const form = useApplicationForm({
    clearInputErrorOnChange: true,
    validate:
      flow[currentStep].validation && yupResolver(flow[currentStep].validation),
    initialValues: application.data,
  });
  const [canContinueApplication, setCanContinueApplication] = useState(false);
  const isFirstStep = currentStep === 0;
  const isMaxStep = currentStep + 1 > application.maxSteps;
  const [isRefetching, setIsRefetching] = useState(false);

  useEffect(() => {
    initApplication();
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", initApplication);

    return () => {
      window.removeEventListener("popstate", initApplication);
    };
  }, []);

  useEffect(() => {
    const newData = { ...data };
    newData.firstName = newData?.firstName?.replace("Unknown", "");
    newData.lastName = newData?.lastName?.replace("Unknown", "");
    newData.marketId =
      newData.marketId ?? `${process.env?.ZERO_MARKET_ID ?? -1}`;

    let serverCurrentStep = data?.currentStep;
    if (serverCurrentStep === "market") {
      serverCurrentStep = "basic-qualifications";
    }
    let currentStepIndex = serverCurrentStep
      ? findIndex(flow, ["path", serverCurrentStep])
      : application.currentStep;

    while (currentStepIndex < flow.length) {
      if (
        flow[currentStepIndex]?.skipStep &&
        flow[currentStepIndex].skipStep(form, auth)
      ) {
        currentStepIndex++;
      } else {
        break;
      }
    }

    setApplication({
      ...application,
      currentStep: currentStepIndex,
      data: {
        ...application.data,
        ...newData,
      },
    });

    form.setValues(newData);

    // if (data?.status && data.status !== "started") {
    //   navigate(`/dashboard`);
    // }
  }, [data]);

  useEffect(() => {
    if (
      application?.data?.status &&
      application?.data?.status !== "started" &&
      application.isFinished
    ) {
      navigate(`/dashboard`);
    }
  }, [application]);

  useEffect(() => {
    preValidation();
  }, [form.values]);

  useEffect(() => {
    flow[currentStep].preValidation = formPreValidation.getScheme(
      flow[currentStep].validation
    );
    preValidation();
  }, [currentStep]);

  /**
   * Redirect to last step the user has been on
   */
  useEffect(() => {
    if (application.isFinished) {
      return;
    }

    const currentURLStep =
      findIndex(flow, ["path", window.location.pathname.split("/").pop()]) || 0;

    if (currentURLStep !== currentStep) {
      navigate(`/application/${flow[currentStep].path}`);
    }
  }, [currentStep, navigate]);

  const initApplication = () => {
    const defaultStep =
      findIndex(flow, ["path", window.location.pathname.split("/").pop()]) || 0;
    setApplication({
      ...application,
      currentStep: defaultStep > -1 ? defaultStep : 0,
      maxStep: flow.length - 1,
    });
  };

  const goToNextStep = async () => {
    if (form.validate().hasErrors) {
      return;
    }

    let nextStepIndex = currentStep + 1;
    while (nextStepIndex < flow.length) {
      if (
        flow[nextStepIndex]?.skipStep &&
        flow[nextStepIndex].skipStep(form, auth)
      ) {
        nextStepIndex++;
      } else {
        break;
      }
    }

    if (!isMaxStep) {
      const nextStep = flow[nextStepIndex].path;
      const completedStep = flow[currentStep].path;
      const newData = {
        ...application.data,
        ...form.values,
        currentStep: nextStep,
        completedStep,
      };

      const { status, ...restData } = newData;
      const response = await saveData(restData);
      if (
        !response?.qualified &&
        (response?.status === "rejected" || response.status === "auto_rejected")
      ) {
        navigate(`/basic-qualifications-denied`);
      } else {
        setApplication({
          ...application,
          data: newData,
          currentStep: nextStepIndex,
        });
        refetch();
        navigate(`/application/${nextStep}`);
      }
    }
  };

  const skipStep = () => {
    const nextStepIndex = currentStep + 1;

    // If we skip the step we need to reset the values
    const currentFields = Object.keys(flow[currentStep].validation?.fields);
    currentFields.forEach((field) => {
      form.setFieldValue(field, "");
    });

    if (!isMaxStep) {
      const nextStep = flow[nextStepIndex].path;
      setApplication({
        ...application,
        data: {
          ...application.data,
          ...form.values,
          currentStep: nextStep,
        },
        currentStep: nextStepIndex,
      });
      navigate(`/application/${nextStep}`);
    } else {
      // Submit async call
    }
  };

  const goBackStep = async () => {
    let nextStepIndex = currentStep - 1;
    while (nextStepIndex >= 0) {
      if (
        flow[nextStepIndex]?.skipStep &&
        flow[nextStepIndex].skipStep(form, auth)
      ) {
        nextStepIndex--;
      } else {
        break;
      }
    }

    const nextStep = flow[nextStepIndex].path;

    setApplication({
      ...application,
      currentStep: nextStepIndex,
      data: {
        ...application.data,
        currentStep: flow[nextStepIndex].path,
      },
    });
    await saveData({
      ...application.data,
      currentStep: flow[nextStepIndex].path,
    });
    navigate(`/application/${nextStep}`);
  };

  const goBack = () => {
    navigate(-1);
  };

  const submit = async () => {
    if (form.validate().hasErrors) {
      return;
    }

    const isFinished = isMaxStep;

    const newData = {
      ...application.data,
      ...form.values,
    };

    setApplication({
      ...application,
      isFinished: isFinished,
      data: newData,
    });

    try {
      const [, submitFormResult] = await Promise.all([
        saveData(newData),
        submitForm(),
      ]);

      if (submitFormResult.errors && submitFormResult.errors.length > 0) {
        form.setErrors(submitFormResult.errors);

        return;
      }

      setIsRefetching(true);
      await refetch();
      setIsRefetching(false);
      navigate("/dashboard");

      return;
    } catch (error) {
      form.setErrors({ submit: error.message });
      return;
    }
  };

  const optOutApplication = () => {
    navigate("/application/opt-out");
  };

  const preValidation = () => {
    if (!flow[currentStep].preValidation) {
      return;
    }

    flow[currentStep].preValidation.isValid(form.values).then((isValid) => {
      if (isValid) {
        setCanContinueApplication(true);
      } else {
        setCanContinueApplication(false);
      }
    });
  };

  return {
    form,
    loading: isLoadingSave || isLoading || isLoadingSubmit || isRefetching,
    goToNextStep,
    goBackStep,
    goBack,
    skipStep,
    isFirstStep,
    isMaxStep,
    currentStep,
    submit,
    optOutApplication,
    canSkip: flow[currentStep].skip,
    hideButtons: flow[currentStep].hideButtons,
    canContinueApplication: canContinueApplication ?? true,
    canEndApplication: flow[currentStep].canEndApplication,
    backButtonDisabled: flow[currentStep].backButtonDisabled,
  };
};

export default useForm;
