import { gql } from "graphql-request";
import { showNotification } from "@mantine/notifications";

import { gqlRequest } from "@/utils/request";

export const getPaymentIntent = async ({
  utmSource,
  utmCampaign,
  utmMedium,
}) => {
  const mutation = gql`
    mutation SittersCreatePaymentIntent(
      $utmSource: String
      $utmCampaign: String
      $utmMedium: String
    ) {
      sitter {
        createPaymentIntent(
          utmSource: $utmSource
          utmCampaign: $utmCampaign
          utmMedium: $utmMedium
        ) {
          paymentIntent {
            id
            amount
            clientSecret
          }
          membershipPrice {
            recurring {
              interval
            }
            unitAmount
          }
          sitter {
            activePromo
            freeTrial
          }
          setupIntent {
            clientSecret
          }
        }
      }
    }
  `;

  const variables = {
    utmSource,
    utmCampaign,
    utmMedium,
  };

  try {
    const data = await gqlRequest.request(mutation, variables);
    return data?.sitter?.createPaymentIntent;
  } catch (err) {
    console.log(err);
    showNotification({
      color: "red",
      title: "Save Error",
      message: "Something wen't wrong, please refresh the page 😟",
    });
  }
  return {};
};
