import { useState } from "react";

import Uppy from "@uppy/core";
import FileInput from "@uppy/file-input";
import AwsS3Multipart from "@uppy/aws-s3-multipart";

import { useApplicationFormContext } from "@/pages/Application/form-context";
import { setApplicationData } from "@/services/application";
import useGetUserData from "@/hooks/useGetUserData";

const useProfilePhotoUpload = ({
  onUpload = () => {},
  onUploadSuccess = () => {},
  onError = () => {},
} = {}) => {
  const [uploading, setUploading] = useState(false);
  const form = useApplicationFormContext();
  const { refetch } = useGetUserData();

  const uppy = ({ onUpload, onUploadSuccess, onError }) => {
    return new Uppy({ autoProceed: true })
      .use(FileInput)
      .use(AwsS3Multipart, { companionUrl: process.env.REACT_APP_BACKEND_URL })
      .on("upload", () => {
        setUploading(true);
        onUpload();
      })
      .on("upload-success", async (file, response) => {
        const uploadedFileData = JSON.stringify({
          // eslint-disable-next-line no-useless-escape
          id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
          storage: "cache",
          metadata: {
            size: file.size,
            filename: file.name,
            mime_type: file.type,
          },
        });

        try {
          const applicationData = await setApplicationData({
            ...form.values,
            imageData: uploadedFileData,
          });
          form.setFieldValue("imageUrl", applicationData.imageUrl);
          // form.setFieldValue("imageData", uploadedFileData);
          onUploadSuccess(uploadedFileData, applicationData.imageUrl);
          refetch();
          setUploading(false);
        } catch (err) {
          setUploading(false);
          onError(err);
        }
      });
  };

  const uppyClient = uppy({
    onUpload,
    onUploadSuccess,
    onError,
  });

  const handleUpload = (file) => {
    try {
      uppyClient.addFile({
        source: "file input",
        name: file.name,
        type: file.type,
        data: file,
      });
    } catch (err) {
      if (err.isRestriction) {
        // handle restrictions
        console.log("Restriction error:", err);
      } else {
        // handle other errors
        console.error(err);
      }
    }
  };

  return {
    form,
    upload: handleUpload,
    uploading,
  };
};

export default useProfilePhotoUpload;
