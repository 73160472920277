import React, { useEffect, useState, useMemo } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Text, LoadingOverlay, Alert, Box, Flex } from "@mantine/core";

import { useApplicationFormContext } from "@/pages/Application/form-context";
import useForm from "@/pages/Application/hooks/useForm";
import { CheckoutForm } from "@/components/Stripe/CheckoutForm";
import { SetupIntentForm } from "@/components/Stripe/SetupIntentForm";
import { useProgressBar } from "@/contexts/ProgressBarContext";
import { usePaymentIntent } from "@/hooks/usePaymentIntent";
// import successImage from "@/assets/images/payment-success-48.svg";
import useUtmParams from "@/hooks/useUtmParams";
import { PricingInfo } from "./components/PricingInfo";
import { MembershipBenefitsList } from "./components/MembershipBenefitsList";
import { getFormattedInterval } from "./utils/formatters";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const stripeOptionsAppearance = {
  theme: "stripe",
  labels: "floating",
  variables: {
    fontFamily: " SofiaRegular,Arial",
    fontLineHeight: "1",
    borderRadius: "8px",
  },
  rules: {
    ".Block": {
      padding: "12px",
    },
    ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
      borderColor: "var(--colorPrimaryText)",
      boxShadow:
        "0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
    },
    ".Input": {
      padding: "5px 12px",
      fontSize: "14px",
    },
    ".Label": {
      color: "#77808e",
      fontSize: "14px",
    },
  },
};

const SubscriptionPayment = () => {
  useProgressBar(8);
  const { goToNextStep } = useForm();
  const {
    paymentIntent,
    membershipInterval,
    membershipPrice,
    sitter,
    setupIntent,
    isLoading: isPaymentIntentLoading,
  } = usePaymentIntent(useUtmParams());

  const form = useApplicationFormContext();

  const [loading, setLoading] = useState(true);

  const derivedValues = useMemo(() => {
    const amount = paymentIntent?.amount;
    return {
      membershipPriceAmount: membershipPrice?.unitAmount
        ? (membershipPrice.unitAmount / 100).toFixed()
        : "...",
      formattedAmount: amount ? (amount / 100).toFixed() : "...",
      monthlyPrice: amount ? (amount / 12 / 100).toFixed(2) : "...",
      formattedInterval: getFormattedInterval(
        membershipInterval,
        form.values.membershipInterval
      ),
      paymentIntentClientSecret: paymentIntent?.clientSecret,
      setupIntentClientSecret: setupIntent?.clientSecret,
    };
  }, [
    paymentIntent,
    setupIntent,
    membershipPrice,
    membershipInterval,
    form.values.membershipInterval,
  ]);

  const stripeOptions = (clientSecret) => ({
    clientSecret: clientSecret,
    appearance: stripeOptionsAppearance,
    layout: {
      type: "tabs",
      defaultCollapsed: false,
    },
    locale: "en",
  });

  useEffect(() => {
    if (window.location.search.indexOf("payment_intent") !== -1) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    goToNextStep();
  };

  const renderStripeElement = () => {
    const { setupIntentClientSecret, paymentIntentClientSecret } =
      derivedValues;

    if (setupIntentClientSecret) {
      return (
        <Elements
          stripe={stripePromise}
          options={stripeOptions(setupIntentClientSecret)}
        >
          <SetupIntentForm
            clientSecret={setupIntentClientSecret}
            onSubmit={handleSubmit}
            onReady={() => setLoading(false)}
            onChange={() => {}}
          />
        </Elements>
      );
    }

    if (paymentIntentClientSecret) {
      return (
        <Elements
          stripe={stripePromise}
          options={stripeOptions(paymentIntentClientSecret)}
        >
          <CheckoutForm
            clientSecret={paymentIntentClientSecret}
            onSubmit={handleSubmit}
            onReady={() => setLoading(false)}
            onChange={() => {}}
          />
        </Elements>
      );
    }

    return null;
  };

  return (
    <div>
      <LoadingOverlay visible={isPaymentIntentLoading || loading} />

      <PricingInfo
        isFreeTrial={sitter?.freeTrial}
        hasActivePromo={sitter?.activePromo}
        formattedAmount={derivedValues.formattedAmount}
        formattedInterval={derivedValues.formattedInterval}
        monthlyPrice={derivedValues.monthlyPrice}
        membershipInterval={membershipInterval}
      />

      <Text mt="sm" fw={500}>
        Your membership includes:
      </Text>
      <MembershipBenefitsList
        membershipPriceAmount={derivedValues.membershipPriceAmount}
        formattedInterval={derivedValues.formattedInterval}
      />

      {!derivedValues.setupIntentClientSecret && !sitter?.freeTrial && (
        <Alert
          mt="xl"
          mb="sm"
          style={{
            backgroundColor: "#F7F7F7",
            border: "1px solid #DEDEDE",
            borderRadius: 20,
          }}
        >
          <Flex align="center" direction="row">
            <Box>
              <Text fz="sm" fw={500} align="center">
                You won't be charged until your application is approved. Cancel
                anytime after that.
              </Text>
            </Box>
          </Flex>
        </Alert>
      )}

      {renderStripeElement()}
    </div>
  );
};

export default SubscriptionPayment;
