import { gqlRequest } from "utils/request";
import { gql } from "graphql-request";
import { showNotification } from "@mantine/notifications";

import { getLocalStorageAuth } from "@/utils/auth";

export const getUserData = async () => {
  const query = gql`
    query {
      sitter {
        id
        email
        freeTrial
        paymentMethodRegistered
        myInterviewSignature
        personalInformation {
          college {
            id
            title
          }
        }
        sitterApplication {
          agreedToReceiveTexts
          address1
          address2
          city
          collegeLevel
          copyRequested
          currentStep
          disclosureAgreement
          dob
          driversLicenseNo
          driversLicenseState
          eduEmail
          fcraAgreement
          gpa
          hearAboutUs
          id
          imageData
          imageUrl
          firstName
          lastName
          legalFirstName
          legalLastName
          legalMiddleName
          major
          marketId
          myInterviewVideoId
          videoSubmittedAt
          noMiddleName
          paidSittingExperience
          phone
          qualified
          reliableTransportation
          sitterStatus
          signature
          ssn
          state
          zip
          status
          paidSittingExperienceDetails
          freeTimeDetails
          schoolMajorDetails
          hearAboutUsDetails
          whyDoYouWantToBeAWyndy
          membershipInterval
          paymentIntent {
            status
          }
        }
      }
    }
  `;

  const user = getLocalStorageAuth();

  if (!user.isAuth) {
    return;
  }

  try {
    const data = await gqlRequest.request(query);
    return {
      ...data?.sitter?.sitterApplication,
      sitterId: data.sitter.id,
      email: data.sitter.email,
      freeTrial: data.sitter.freeTrial,
      paymentMethodRegistered: data.sitter.paymentMethodRegistered,
      myInterviewSignature: data.sitter.myInterviewSignature,
      collegeId: data?.sitter?.personalInformation?.college?.id,
      collegeName: data?.sitter?.personalInformation?.college?.title,
      imageData: null,
    };
  } catch (err) {
    showNotification({
      color: "red",
      title: "Data fetch error",
      message: "Something wen't wrong, please refresh the page 😟",
    });
  }
  return {};
};

export const setApplicationData = async (fields) => {
  const mutation = gql`
    mutation sitterUpdateSitterApplicationV2(
      $agreedToReceiveTexts: Boolean
      $address1: String
      $address2: String
      $city: String
      $collegeLevel: String
      $collegeState: String
      $collegeId: String
      $dob: String
      $driversLicenseNo: String
      $driversLicenseState: String
      $eduEmail: String
      $gpa: String
      $hearAboutUs: String
      $imageData: String
      $legalFirstName: String
      $legalLastName: String
      $legalMiddleName: String
      $major: String
      $marketId: String
      $noMiddleName: Boolean!
      $paidSittingExperience: String
      $phone: String
      $reliableTransportation: Boolean
      $signature: String
      $ssn: String
      $state: String
      $zip: String
      $firstName: String
      $lastName: String
      $currentStep: String
      $completedStep: String
      $fcraAgreement: Boolean!
      $copyRequested: Boolean!
      $disclosureAgreement: Boolean!
      $paidSittingExperienceDetails: String
      $freeTimeDetails: String
      $schoolMajorDetails: String
      $hearAboutUsDetails: String
      $whyDoYouWantToBeAWyndy: String
      $myInterviewVideoId: String
      $videoSubmittedAt: String
    ) {
      sitterUpdateSitterApplicationV2(
        agreedToReceiveTexts: $agreedToReceiveTexts
        address1: $address1
        address2: $address2
        city: $city
        collegeLevel: $collegeLevel
        collegeState: $collegeState
        collegeId: $collegeId
        copyRequested: $copyRequested
        disclosureAgreement: $disclosureAgreement
        dob: $dob
        driversLicenseNo: $driversLicenseNo
        driversLicenseState: $driversLicenseState
        eduEmail: $eduEmail
        fcraAgreement: $fcraAgreement
        gpa: $gpa
        hearAboutUs: $hearAboutUs
        imageData: $imageData
        legalFirstName: $legalFirstName
        legalLastName: $legalLastName
        legalMiddleName: $legalMiddleName
        major: $major
        marketId: $marketId
        noMiddleName: $noMiddleName
        paidSittingExperience: $paidSittingExperience
        phone: $phone
        reliableTransportation: $reliableTransportation
        signature: $signature
        ssn: $ssn
        state: $state
        zip: $zip
        firstName: $firstName
        lastName: $lastName
        currentStep: $currentStep
        completedStep: $completedStep
        paidSittingExperienceDetails: $paidSittingExperienceDetails
        freeTimeDetails: $freeTimeDetails
        schoolMajorDetails: $schoolMajorDetails
        hearAboutUsDetails: $hearAboutUsDetails
        whyDoYouWantToBeAWyndy: $whyDoYouWantToBeAWyndy
        myInterviewVideoId: $myInterviewVideoId
        videoSubmittedAt: $videoSubmittedAt
      ) {
        agreedToReceiveTexts
        address1
        address2
        city
        collegeLevel
        copyRequested
        currentStep
        disclosureAgreement
        dob
        driversLicenseNo
        driversLicenseState
        eduEmail
        fcraAgreement
        gpa
        hearAboutUs
        imageData
        imageUrl
        legalFirstName
        legalLastName
        legalMiddleName
        major
        marketId
        noMiddleName
        paidSittingExperience
        phone
        qualified
        reliableTransportation
        signature
        ssn
        state
        zip
        firstName
        lastName
        status
        currentStep
        paidSittingExperienceDetails
        freeTimeDetails
        schoolMajorDetails
        hearAboutUsDetails
        whyDoYouWantToBeAWyndy
        myInterviewVideoId
        videoSubmittedAt
      }
    }
  `;

  try {
    const data = await gqlRequest.request(mutation, fields);
    return data?.sitterUpdateSitterApplicationV2;
  } catch (err) {
    showNotification({
      color: "red",
      title: "Save Error",
      message:
        "Something wen't wrong when trying to save your application data, please try again",
    });
  }
  return {};
};

export const submitInterviewVideo = async (fields) => {
  const mutation = gql`
    mutation sitterSubmitMyInterviewVideo($myInterviewVideoId: String!) {
      sitterSubmitMyInterviewVideo(myInterviewVideoId: $myInterviewVideoId) {
        sitterApplication {
          id
          status
        }
      }
    }
  `;

  try {
    const data = await gqlRequest.request(mutation, fields);
    return data?.sitterSubmitMyInterviewVideo;
  } catch (err) {
    console.log(err);
    showNotification({
      color: "red",
      title: "Submit Error",
      message:
        "Something wen't wrong when trying to submit your video interview, please try again",
    });
  }
  return {};
};

export const submitApplication = async () => {
  const mutation = gql`
    mutation SitterCompleteSitterApplication {
      sitterCompleteSitterApplication {
        errors {
          field
          message
        }
        sitterApplication {
          id
          status
        }
      }
    }
  `;

  try {
    const data = await gqlRequest.request(mutation);
    const errors = data?.sitterCompleteSitterApplication?.errors;
    if (errors && errors.length > 0) {
      showNotification({
        color: "red",
        title: "Submit Error",
        message: (
          <div>
            <p>We noticed some issues with your application:</p>
            <ul>
              {errors.map((error, index) => (
                <li key={index}>
                  <strong>{error.field}</strong>: {error.message}
                </li>
              ))}
            </ul>
            <p>
              Please try going back and start from the first step to complete
              the missing information.
            </p>
          </div>
        ),
      });

      return;
    }

    return data?.sitterCompleteSitterApplication;
  } catch (err) {
    showNotification({
      color: "red",
      title: "Submit Error",
      message:
        "Something wen't wrong when trying to submit your application, please try again",
    });
  }
  return {};
};

export const optOut = async (fields) => {
  const mutation = gql`
    mutation SitterOptOutOfApplication(
      $optOutReason: String!
      $optOutNotes: String
    ) {
      sitterOptOutOfApplication(
        optOutReason: $optOutReason
        optOutNotes: $optOutNotes
      ) {
        sitterApplication {
          id
          status
          optOutReason
          optOutNotes
        }
      }
    }
  `;

  const data = await gqlRequest.request(mutation, fields);
  return data?.sitterApplication;
};
